import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { theme } from '@dfds-ui/theme'
import { Text } from '@dfds-ui/typography'
import { useTranslation } from 'react-i18next'

const PriceContainerTop = styled.div`
  padding: 0 20px 20px;
  text-align: right;
  position: absolute;
  bottom: -1px;
  right: -30px;
  transform: skewX(-30deg);
  padding: 0;
  background-color: ${theme.colors.secondary.main};
`

const PriceContent = styled.div`
  padding: 10px 50px 10px 30px;
  color: ${theme.colors.text.light.primary};
  transform: skewX(30deg);
`

const LinkboxCarouselItemOverlay = (props) => {
  const {
    showFromPrice,
    fromPriceAmount,
    currency,
    placeCurrency,
    fromHeadline,
    pricePostText,
  } = props
  const { t } = useTranslation()

  return (
    <PriceContainerTop>
      <PriceContent>
        {!fromHeadline && (
          <Text as={'div'} styledAs={'bodyInterfaceSmall'}>
            {showFromPrice !== false
              ? currency === '%'
                ? t('GENERAL-SAVE')
                : t('GENERAL-FROM')
              : '\u00A0'}
          </Text>
        )}
        <div
          css={css`
            display: flex;
            align-items: flex-end;
            justify-content: ${placeCurrency === false
              ? 'flex-start'
              : 'flex-end'};
            flex-direction: ${placeCurrency === false ? 'row-reverse' : 'row'};
          `}
        >
          {fromPriceAmount && currency && (
            <Text
              as={'span'}
              styledAs={'subHeadline'}
              css={css`
                margin: ${placeCurrency === false ? '0 0 0 4px' : '0 4px 0 0'};
              `}
            >
              {currency}
            </Text>
          )}
          <Text
            as={'span'}
            styledAs={'sectionHeadline'}
            css={
              fromPriceAmount &&
              css`
                font-size: 40px;
                line-height: 1;
              `
            }
          >
            {fromPriceAmount
              ? fromPriceAmount.toFixed(2).replace(/[.,]00$/, '')
              : fromHeadline}
          </Text>
        </div>
        <Text
          as={'div'}
          styledAs={'bodyInterfaceSmall'}
          css={css`
            color: ${theme.colors.text.light.primary};
          `}
        >
          {pricePostText ? pricePostText : '\u00A0'}
        </Text>
      </PriceContent>
    </PriceContainerTop>
  )
}

export default LinkboxCarouselItemOverlay
